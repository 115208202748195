<template>
  <nav class="menu-container">
    <router-link
      :exact="item.exact"
      v-for="item in items"
      :key="item.link"
      :to="{ name: item.name }"
    >
      <span>{{ $t(item.title) }}</span>
    </router-link>

    <span>
      <a v-show="'en'=== $i18n.locale" @click="changeLanguage('zh')" command="zh">简体中文</a>
      <a v-show="'zh'=== $i18n.locale" @click="changeLanguage('en')" command="en">English</a>
    </span>
  </nav>
</template>
  
<script>
import { isMobile } from "@/units/isMobile";

export default {
  name: "menuBox",
  components: {
    // Icon,
  },
  data() {
    return {
      items: [
        {
          name: "Home",
          title: "nav.dashboard",
          exact: true,
        },
        {
          name: "Download",
          title: "nav.downLoadNav",
          exact: true,
        },
      ],
    };
  },
  computed: {},
  created() {
    // console.log(i18n)
    // console.log(isMobile(),'[[[[[[]]]]]]')
    if (isMobile()) {
      this.items.pop();
    }
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang; // 设置给本地的i18n插件
      // console.log(this.$i18n.locale, lang);
      // this.$message.success("切换多语言成功");
    },
  },
  // methods: {
  //   isSelected(item) {
  //     let link = item.link.toLowerCase();
  //     let curPathname = location.pathname.toLowerCase();
  //     if (item.startWidth) {
  //       return curPathname.startsWith(link);
  //     } else {
  //       return curPathname === link;
  //     }
  //   },
  // },
};
</script>


<style lang="less" scoped>
// ~@ 代表src路径
@import "~@/assets/var.less";
.menu-container {
  a {
    cursor: pointer;
    color: @lightWords;
    padding: 0 4rem;
    font-weight: bold;
    font-size: @fontSize32;
    &:hover {
      color: @lightWordsHover;
    }
    &.router-link-active {
      color: @lightWordsActive;
    }
  }
}
</style>

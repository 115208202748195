export function downloadHostLink() {
  return [
    {
      id: "1",
      btn: "../../hhpoker/index-download-ios",
      hostLink: "https://vq15pst.shangdaocc.com/3264326b346b34",
    },
    {
      id: "2",
      btn: "../../hhpoker/index-download-android",
      hostLink:
        "https://download.litterzinc.com/apk/hp_277001_mobile_produce_202408021355.apk",
    },
  ]
}


export function downloadIos() {
  return [
    {
      id: "1",
      iosBtnImg: "../../hhpoker/iosHostBtn",
      url: "https://vq15pst.shangdaocc.com/3264326b346b34",
      flag: "../../hhpoker/recommend"
    },
    {
      id: "2",
      iosBtnImg: "../../hhpoker/iosReservebtn1",
      url: "https://ptsk2pf.91wawacai.com/file/2p5svlv"
    },
    {
      id: "3",
      iosBtnImg: "../../hhpoker/iosReservebtn2",
      url: "https://ptsk2pf.91wawacai.com/file/2p5svlv"
    },
    {
      id: "4",
      iosBtnImg: "../../hhpoker/iosReservebtn3",
      url: "https://hpokerekcv.s3-accelerate.amazonaws.com/index.html"
    },
  ]
}
export function downloadAndroid() {
  return [
    {
      id: "1",
      androidBtnImg: "../../hhpoker/androidHostBtn",
      url: "https://download.litterzinc.com/apk/hp_277001_mobile_produce_202408021355.apk",
      flag: "../../hhpoker/recommend"
    },
    {
      id: "2",
      androidBtnImg: "../../hhpoker/androidReservebtn1",
      url: "https://download.litterzinc.com/apk/hp_276011_mobile_produce_202406041140.apk"
    }
  ]
}



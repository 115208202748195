<template>
  <div class="tabs-container">
    <div class="tabs-container-nav">
      <div
        v-for="(item, index) in tabNavList"
        @click.stop="handleTabNavClick(item, index)"
        :class="['tab-nav-item', item.name == activeName ? 'active' : '']"
        :key="index"
      >
        <Img
          class="icon"
          :width="'5rem'"
          :height="'5rem'"
          :url="require('@/assets/images/'+item.ico + active +'.png')"
        />
        <Img
          v-show="item.name == activeName"
          :width="'5rem'"
          :height="'5rem'"
          :url="require('@/assets/images/'+ item.icoActive +'.png')"
        />
        <span class="text" v-if="item.text">{{ $t(item.text) }}</span>
      </div>
    </div>
    <!-- 滚动滑块 -->
    <div class="tab-content-wrap">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Img from "@/components/Image";
export default {
  name: "TabS",
  props: {
    // v-model的那项
    value: {
      type: String,
    },
  },
  components: { Img },
  data() {
    return {
      // tab数组
      tabNavList: [],
      // 当前活跃项
      activeName: "",
      // 当前活跃索引
      currentIndex: 0,
      // 是否选中
      active: "",
    };
  },
  mounted() {
    this.init();
  },
  created() {
    // console.log(this.tabNavList);
  },
  methods: {
    // 初始化
    init() {
      this.activeName = this.value;
    },
    // 设置tab点击栏
    setTabBar(tabsPaneInstance) {
      // tab的描述信息可以是字符串也可以是render函数
      const label = tabsPaneInstance.label,
        type = typeof label;
      // 添加到数组项中，根据添加条件渲染
      this.tabNavList.push({
        text: type == "function" ? "" : label,
        // renderFun: type == "function" ? label : "",
        name: tabsPaneInstance.name,
        ico: tabsPaneInstance.ico,
        icoActive: tabsPaneInstance.icoActive,
      });
    },
    handleTabNavClick(item, index) {
      if (item.name == this.activeName) return;
      // 更新当前活跃项
      this.activeName = item.name;
      // 活跃项的索引
      this.currentIndex = index;
    },
  },
};
</script>

<style lang="less">
@import "~@/assets/var.less";
@import "~@/assets/mixin.less";
.tabs-container {
  .tabs-container-nav {
    .flex-center();
    border-radius: 3rem 3rem 0 0;
    overflow: hidden;
    .tab-nav-item {
      .flex-center();
      flex: 1;
      cursor: pointer;
      color: #999;
      height: 10rem;
      background: linear-gradient(
        0deg,
        rgb(15, 23, 29) 0%,
        rgb(18, 23, 25) 8%,
        rgb(28, 41, 45) 45%,
        rgb(42, 75, 86) 100%
      );
      box-shadow: inset 0px 2px 0px 0px rgba(94 104 109 / 61%);
      border-left: 1px solid #0e1214;
      border-right: 1px solid #617379;
      &:first-of-type {
        border-left: none;
      }
      &:last-of-type {
        border-right: none;
      }
      .img-container {
        object-fit: contain;
      }
      &.active {
        color: @lightWordsActive;
        background: linear-gradient(
          0deg,
          rgb(12, 39, 49) 0%,
          rgb(11, 33, 41) 73%,
          rgb(20, 38, 44) 100%
        );
        box-shadow: inset 0px 2px 0px 0px rgba(94 104 109 / 61%);
        .icon {
          display: none;
        }
      }
      .text {
        margin-left: 1rem;
        font-size: @fontSize32;
      }
    }
  }
}
</style>
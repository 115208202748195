//中文语言包zh.js

export default {
    nav: {
        dashboard: '首页',
        downLoadNav: '游戏下载',
    },
    home: {
        gameplayIntroduction: '玩法介绍',
        contactUs: '联系我们'
    },
    download:{
        scanToDownload:'手机扫码下载',
        downLoadTips: "请您先卸载APP再重新下载。 IOS包安装便捷,安装包有掉签风险如提示{name}时,请到官网重新下载即可。",
        tips:'“无法验证APP”',
        CommonTitle:'常见问题',
        questionTitle1:'1、什么是长效签名包?',
        questiontext1:'什么是长效签名包可在iPhone、iPad下载,与普通版相比，可以有效減少软件频繁授权签名次数，服务由第三方提供，有效期内重复安装都是免费的。',
        questionTitle2:'2、软件下载安装后无法打开或闪退,怎么办?',
        questiontext2:'您只需先卸载已安装的APP,在下载页面重新安装。应用下载安装后,在“设置-通用-描述文件与设备管理”中信任APP软件,即可正常使用.',
        // 安装教程
        iosInstallationGuide:'iOS 安装教程',
        testFlightInstallationGuide:'TESTFLIGHT安装教程',
        androidInstallationGuide:'ANDROID安装教程',
        // ios
        iosTepts1:'1、点击下载按钮，选择安装',
        iosTepts2:'2、进入设置—通用—VPN与设备管理',
        iosTepts3:'3、点击相应企业级APP,选择信任开发者,点击信任',
        iosTepts4:'4、所有安装步骤已完成，现在可以开始游戏了',
        // android
        androidTepts1:'1、前往官网,点击安卓下载',
        androidTepts2:'2、点击 安卓下载,若提示是否下载: 选择"下载"',
        androidTepts3:'3、至设备中的文件储存位置内查看已经下载完成的安装档',
        androidTepts4:'4、点击安装',
        androidTepts5:'5、安装完成后即可打开APP',
        androidTepts6:'6、若提示:专用旧版打造信息,点击确定即可',
        androidTepts7:'7、打开后提示需要权限,皆点击"允许"即可',
        androidTepts8:'8、安装步骤已完成,可正常登录游戏',
    },
    footer: {
        coperight: 'COPYRIGHT©HH POKER. All RIGHTS RESERVED.'
    }
}
<template>
  <!-- Video组件 -->
  <div class="video-player-container" @click="handleClick">
    <Img
      :width="videoDetal.coverWdith"
      :height="videoDetal.coverHeight"
      :url="videoDetal.img + '-' + $i18n.locale +'.png'"
    />
    <video
      v-show="videoState"
      class="video"
      @click.stop="addStatus"
      :src="videoDetal.video + '.mp4'"
      controls="controls"
      ref="vueRef"
    ></video>
    <div @click="stopPlay" v-show="videoState" class="mask"></div>
  </div>
</template>
  
<script>
import Img from "@/components/Image";

export default {
  name: "CommonVideo",
  components: {
    Img,
  },
  props: ["videoDetal"],
  data() {
    return {
      videoState: false,
    };
  },
  created() {
    // console.log(this.videoDetal, "5656565");
  },
  methods: {
    handleClick() {
      this.videoState = !this.videoState;
    },
    stopPlay() {
      this.$refs.vueRef.pause();
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/var.less";
@import "~@/assets/mixin.less";

.video-player-container {
  position: relative;
  cursor: pointer;
  .video {
    .self-center(fixed);
    height: 100%;
    z-index: 2;
  }
  .mask {
    .self-center(fixed);
    width: 100%;
    height: 100%;
    background: @dark;
    opacity: 0.5;
    z-index: 1;
  }
}
</style>
<template>
  <div class="download-container">
    <!-- pc -->
    <div v-if="!this.$device.ios && !this.$device.android" class="wrap">
      <!-- 手机扫码下载 -->
      <div class="qr-box">
        <Img :width="'40rem'" :height="'40rem'" :url="imgUrl" />
        <div class="qr-box-text">{{ $t('download.scanToDownload') }}</div>
      </div>
      <div class="dowmload-title">
        <Img
          :width="'6.4rem'"
          :height="'6.4rem'"
          :url="require('@/assets/images/'+'download-tipicon1.png')"
        />
        <!-- <p class="title">{{ $t('download.downLoadTips') }}</p> -->
        <i18n path="download.downLoadTips" tag="p" class="title">
          <template v-slot:name>
            <span class="eye-catching">{{ $t('download.tips') }}</span>
          </template>
        </i18n>
      </div>
      <div class="tutorials-box">
        <Tabs v-model="tabName">
          <TabPane
            :label="('download.iosInstallationGuide')"
            ico="tabs-ios-img"
            icoActive="tabs-ios-img-active"
            name="name1"
          >
            <IosTutorials />
          </TabPane>
          <TabPane
            :label="('download.androidInstallationGuide')"
            ico="tabs-android-img"
            icoActive="tabs-android-img-active"
            name="name2"
          >
            <AndroidTutorials />
          </TabPane>
        </Tabs>
      </div>
      <Question />
    </div>
  </div>
</template>

<script>
import Img from "@/components/Image";
import IosTutorials from "./IosTutorials";
import AndroidTutorials from "./AndroidTutorials";
import Question from "./Question";
import { Tabs, TabPane } from "@/components/Tabs";
import QRCode from "qrcode";
export default {
  name: "downloadContainer",
  components: {
    Img,
    Tabs,
    TabPane,
    IosTutorials,
    AndroidTutorials,
    Question,
  },
  data() {
    return {
      tabName: "name1",
      imgUrl: "",
    };
  },
  // beforeCreate() {
  //   console.log("beforeCreate", this.banner);
  // },
  created() {
    this.getQRCodeUrl();
    if (this.$device.ios || this.$device.android) {
      if (this.$router.path !== "/") {
        this.$router.replace("/");
      }
    }
  },
  methods: {
    getQRCodeUrl() {
      QRCode.toDataURL(window.location.href, {
        color: {
          dark: "#000000",
          light: "#ffffff",
        },
      })
        .then((url) => {
          // 获取到url后即可在页面使用--二维码图片
          this.imgUrl = url;
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/var.less";
@import "~@/assets/mixin.less";
.qr-box {
  text-align: center;
  margin: 8rem 0;
  font-size: @fontSize32;
  text-align: center;
  .qr-box-text {
    margin-top: 2rem;
  }
}

.dowmload-title {
  .flex-center();
  font-size: @fontSize32;
  padding: 8rem 0;
  .title {
    flex: 1;
    margin-left: 2rem;
  }
}
</style>
export function contactItems() {
    return [
        {
            id: "1",
            item: "../../hhpoker/email",
            text: "hhsupport@hhpoker777.com",
            url: "mailto: hhsupport@hhpoker777.com",
        },
        {
            id: "2",
            item: "../../hhpoker/tg",
            text: "@hhpoker1",
            url: "https://t.me/hhpoker1"
        },
    ]
}

<template>
  <div class="question-container">
    <div class="dowmload-title">
      <Img
        :width="'6.4rem'"
        :height="'6.4rem'"
        :url="require('@/assets/images/'+'download-tipicon1.png')"
      />
      <p class="title">{{ $t('download.CommonTitle') }}</p>
    </div>
    <div class="question-box">
      <h3 class="title">{{ $t('download.questionTitle1') }}</h3>
      <p class="text">{{ $t('download.questiontext1') }}</p>
    </div>
    <div class="question-box">
      <h3 class="title">{{ $t('download.questionTitle2') }}</h3>
      <p class="text">{{ $t('download.questiontext2') }}</p>
    </div>
  </div>
</template>

<script>
import Img from "@/components/Image";
export default {
  name: "QuestionBox",
  components: {
    Img,
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/var.less";
@import "~@/assets/mixin.less";

.question-container {
  .dowmload-title {
    .flex-center();
    font-size: @fontSize32;
    padding: 16rem 0 8rem 0;
    .title {
      flex: 1;
      margin-left: 2rem;
    }
  }
  .question-box {
    font-size: @fontSize32;
    margin-bottom: 8rem;
    .title {
      color: @lightWordsActive;
      margin-bottom: 4rem;
    }
  }
}
</style>
//英文语言包en.js

export default {
    nav: {
        dashboard: 'Home',
        downLoadNav: 'Download',
    },
    home: {
        gameplayIntroduction: 'Gameplay Introduction',
        contactUs: 'Contact Us'
    },
    download: {
        scanToDownload: 'Scan to Download',
        downLoadTips: 'Please uninstall the current app first and then reinstall it. The iOS installation package is easy to install, but there is a risk of losing the iOS signature. If you see a {name}message, please visit the official website to download it again. Some iOS features require signature verification to function, so please take note.',
        tips:'"Cannot Verify App"',
        CommonTitle: 'Frequently Asked Questions',
        questionTitle1: '1、What is a Long-Term Signature Package?',
        questiontext1: 'A long-term signature package is a specially certified installation file for iPhone and iPad. Compared to the standard version, it reduces the frequency of required authorization signatures. This service is provided by a third party and allows for free reinstallation within the validity period.',
        questionTitle2: '2、What do I do when the app will not open or crashes?',
        questiontext2: 'Please delete the app and download it again from our website Regular download: After the application is downloaded and installed, trust the app in “Settings-General-Description File and Device Management” to use it; LTV download: After installing and crediting the app software using the Tutorial, wait for the app to download and then use it',
        // 安装教程
        iosInstallationGuide: 'iOS Installation Guide',
        testFlightInstallationGuide: 'TESTFLIGHT Installation Guide',
        androidInstallationGuide: 'ANDROID Installation Guide',
        // ios
        iosTepts1: '1、Click the download button and select "Install."',
        iosTepts2: '2、Navigate to Settings—General—VPN & Device Management.',
        iosTepts3: '3、Tap the appropriate enterprise app, select "Trust Developer," and confirm by clicking "Trust."',
        iosTepts4: '4、All installation steps are now complete, and you can begin using the app.',
        // android
        androidTepts1: '1、Go to the official website and click on "Download for Android"',
        androidTepts2: '2、Click on "Download for Android" If prompted to confirm the download, choose "Download"',
        androidTepts3: '3、Check the device‘s file storage location for the completed installation file.',
        androidTepts4: '4、Tap Install',
        androidTepts5: '5、Once the installation is complete, you can open the app.',
        androidTepts6: '6、If prompted with a message about a specialized old version, simply click “Confirm”',
        androidTepts7: '7、If prompted for permissions upon opening, click “Allow” for all requests',
        androidTepts8: '8、Installation steps are complete; you can now log in and play the game.',
    },
    footer: {
        coperight: 'COPYRIGHT©HH POKER. All RIGHTS RESERVED.'
    }
}
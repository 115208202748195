<template>
  <div class="ios-tuorials-container">
    <div class="item" v-for="item in commonTutorials" :key="item.id">
      <TutorialsItems :commonTutorials="item" />
    </div>
  </div>
</template>

<script>
import TutorialsItems from "./TutorialsItems";
// import { iosTutorials } from "@/api/hhpoker/tutorials";
export default {
  components: {
    TutorialsItems,
  },
  data() {
    return {};
  },
  created() {
    this.commonTutorials = this.iosTutorials;
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/var.less";
@import "~@/assets/mixin.less";
.ios-tuorials-container {
  background: @dark2;
  .flex-box(center,stretch);
  padding: 0 3rem;
  overflow: hidden;
  flex-wrap: wrap;
  .item {
    width: calc(25% - 7.5rem);
    margin: 8rem 4rem;
    align-items: stretch;
    &:nth-of-type(4n) {
      margin-right: 0;
    }
    &:nth-of-type(4n + 1) {
      margin-left: 0;
    }
  }
}
</style>
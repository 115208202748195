<template>
  <div class="gnip-tabs-pane">
    <div class="tab-pane-content" v-show="$parent.activeName == name">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // tab项的文本或者render函数
    label: {
      type: [String, Function],
    },
    // 每项标识
    name: {
      type: String,
    },
    ico: {
      type: [String, Function],
    },
    icoActive: {
      type: [String, Function],
    },
  },
  data() {
    return {
      // paneTransitionName: "enter-right",
    };
  },
  created() {
    // 统一tab的数据给父组件进行处理和渲染
    this.$parent.setTabBar(this);
  },
};
</script>

<style lang="less">
.gnip-tabs-pane {
  overflow-x: hidden;
  .enter-right-enter-active {
    transition: transform 0.35s;
  }
  .enter-right-enter {
    transform: translateX(100%);
  }
  .enter-right-to {
    transform: translateX(0);
  }
}
</style>
import Vue from 'vue'
import App from './App.vue'
import "./assets/reset.css"
import "./assets/global.less"
import router from '@/router/index.js'
import i18n from '@/lang'
import device from 'vue-device-detector'
import { isMobile } from '@/units/isMobile'

import { contactItems } from "@/api/hhpoker/contact";
import { bannerItems, introduceItems, logo, footLogo, videoItems } from "@/api/hhpoker/index";
import { downloadIos, downloadAndroid, downloadHostLink } from "@/api/hhpoker/downloadData";
import { iosTutorials, androidTutorials } from "@/api/hhpoker/download";


// import { skin } from '@/units/skin'

// let linksss = '/images/';
// Vue.prototype.$linksss = linksss;
// console.log(process.env.VUE_APP_POKER, '--------')


Vue.use(device)

new Vue({
  router,
  i18n,
  device,
  data() {
    return {
      contactItems: [],
      introduceItems: [],
      downloadIos: [],
      downloadAndroid: [],
      videoItems: [],
      iosTutorials: [],
      androidTutorials: [],
      bannerItems: [],
      downloadHostLink: [],
      coperight: [],
      logo: []
    }
  },
  created() {
    Vue.prototype.contactItems = contactItems();
    Vue.prototype.introduceItems = introduceItems();
    Vue.prototype.downloadIos = downloadIos();
    Vue.prototype.downloadAndroid = downloadAndroid();
    Vue.prototype.videoItems = videoItems();
    Vue.prototype.iosTutorials = iosTutorials();
    Vue.prototype.androidTutorials = androidTutorials();
    Vue.prototype.bannerItems = bannerItems();
    Vue.prototype.downloadHostLink = downloadHostLink();
    Vue.prototype.footLogo = footLogo();
    Vue.prototype.logo = logo();
    if (isMobile()) {
      document.querySelector('html').classList.add('mobile')
    } else {
      document.querySelector('html').classList.add('pc')
    }
  },
  render: h => h(App),
}).$mount('#app')


<template>
  <div class="layout-container">
    <div class="layout-header">
      <slot name="header"></slot>
    </div>
    <div class="layout-contenter">
      <slot name="contenter"></slot>
    </div>
    <div class="layout-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "layOut",
};
</script>
  
<style lang="less" scoped>
.layout-container {
  width: 100%;
  height: 100%;
}
.layout-footer{
  margin-bottom: 4rem;
}
</style>
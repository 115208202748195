<template>
  <div @click="handClick(contactItem.url)" class="contact-item-container">
    <Img :width="width" :url="contactItem.item +'.png'" />
    <span class="text">{{ contactItem.text }}</span>
  </div>
</template>

<script>
import Img from "@/components/Image";
export default {
  components: {
    Img,
  },
  name: "contactItem",
  props: ["contactItem", "width"],
  methods: {
    handClick(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/var.less";
@import "~@/assets/mixin.less";
.contact-item-container {
  .flex-center();
  cursor: pointer;
  .text {
    margin-left: 2rem;
  }
  &.img {
    object-fit: contain;
  }
}
</style>
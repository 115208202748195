<template>
  <div class="coperight-container">
    <Img :width="'33.3rem'" :height="'6.3rem'" :url="this.footLogo.logo + '.png'" />
    <p>{{ $t('footer.coperight')}}</p>
  </div>
</template>

<script>
import Img from "@/components/Image";
export default {
  components: {
    Img,
  },
  data() {
    return {};
  },
  created() {
    // console.log(this.footLogo, "============");
  },
  name: "CopeRightItem",
};
</script>

<style lang="less" scoped>
@import "~@/assets/var.less";
@import "~@/assets/mixin.less";

.coperight-container {
  text-align: center;
  color: @lightWords;
  margin-top: 8rem;
  p {
    margin-top: 4rem;
  }
}
</style>
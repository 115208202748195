<template>
  <Layout>
    <template #header>
      <div class="header">
        <SiteHeader />
      </div>
    </template>
    <template #contenter>
      <div class="contenter">
        <Carouselitem />
        <router-view></router-view>
      </div>
    </template>
    <template #footer>
      <div class="wrap">
        <CopeRight />
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/components/Layout";
import SiteHeader from "@/components/SiteHeader";
import Carouselitem from "@/components/Carouselitem";
import CopeRight from "@/components/CopeRight";
export default {
  components: { Layout, SiteHeader, Carouselitem, CopeRight },
  data() {
    return {
    };
  },
  created() {
  },
};
</script>

<style scoped>

</style>